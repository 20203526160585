<template>
  <div class="order-container">
    <div class="order-menu">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="-7">
          全部
          <img
            v-if="activeIndex == -7"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="1">
          待发货
          <img
            v-if="activeIndex == 1"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="2">
          待收货
          <img
            v-if="activeIndex == 2"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="3">
          已完成
          <img
            v-if="activeIndex == 3"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
        <el-menu-item index="0">
          已取消
          <img
            v-if="activeIndex == 0"
            src="../assets/icon/list-select.png"
          />
        </el-menu-item>
      </el-menu>
    </div>

    <div class="all-order-content">
      <!-- 上拉加载 -->
      <!-- <div
        class="infinite-list-wrapper"
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
      > -->
      <van-list
        v-if="!noOrder"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="load"
      >
        <div
          class="order-item"
          v-for="(item, index) in orderList"
          :key="index"
        >
          <div
            @click="goOrderDetails(item)"
            class="order-type row-between"
          >
            <p class="order-type-tip">{{ item.status == 0  ? '已取消' : item.status == 1  ? '待发货' : item.status == 2  ? '待收货' : '已完成' }}</p>
            <!-- <p class="order-type-orderNo">订单号：{{item.orderId}}</p> -->
          </div>
          <div
            @click="goOrderDetails(item)"
            class="order-details-content row-between"
          >
            <div class="order-img-name row-start">
              <div
                class="order-img-name-img"
                :style="'width: 80px;height: 80px;margin:0 18px;background-color: transparent;border-radius: 4px;'"
              >
                <div class="order-img-name-img-inline">
                  <img
                    style="border-radius: 4px;"
                    :src="item.image"
                    alt=""
                  >
                </div>
              </div>
              <div style="height:100%">
                <p style="font-size:14px;color:#FEFEFE">{{ item.name }}</p>
                <p style="font-size:13px;margin-top:5px;color:#CCCCCC">ID:{{ item.showId && item.showId != '0' ? item.showId : item.uniqueCode }}</p>
              </div>

            </div>
            <div class="order-img-name row-start">
              <p style="font-size:14px;margin-right:1rem">共 {{ item.amount }} 件</p>
            </div>
          </div>
          <div
            v-if="item.status == 1"
            class="order-method"
            style="justify-content: flex-start"
          >
            <div
              @click="orderCancer(item)"
              class="go-pay row-center"
              style="margin-left:1.375rem;"
            >取消订单</div>
          </div>
          <div
            v-if="item.status == 2||item.status == 3"
            class="order-method"
            style="justify-content: flex-start"
          >
            <div
              v-if="item.status == 2"
              @click="orderConfim(item)"
              :class="item.kdNo ? 'go-pay' : 'go-disable-pay'"
              class=" row-center"
              style="margin-left:1.375rem;"
            >确认收货</div>
            <div
              v-if="item.exchange == 1"
              @click="veiwOrder(item)"
              :class="item.kdNo ? 'go-pay' : 'go-disable-pay'"
              class="row-center"
              style="margin-left:1.375rem;"
            >查看物流</div>
            <div
              v-if="item.exchange == 3"
              @click="veiwConpon(item)"
              class="go-pay row-center"
              style="margin-left:1.375rem;"
            >查看</div>
            <div
              v-if="item.exchange == 2"
              @click="veiwCard(item)"
              class="go-pay row-center"
              style="margin-left:1.375rem;"
            >查看卡密</div>
          </div>
        </div>
      </van-list>
      <div
        v-if="noOrder"
        class="col-center"
        style="width:100%;height: calc(100vh - 7rem);"
      >
        <img
          style="width:6.5rem;height:auto;margin-bottom:1.375rem;"
          src="../assets/icon/no-list.png"
        />
        <p style="font-size: 16px;font-family: lantingheiweight;font-weight: 500;color: #666666;line-height: 22px;">暂无数据</p>
      </div>
    </div>
    <el-dialog
      title="确定要取消订单吗？"
      :visible.sync="cancerVisible"
      @confirm="cancerOrderReason()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="cancerVisible = false">取 消</el-button>
        <div
          @click="cancerOrderReason()"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <el-dialog
      title="确定收到货了吗？"
      :visible.sync="confirmVisible"
      @confirm="confirmOrder()"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="confirmVisible = false">取 消</el-button>
        <div
          @click="orderConfirm()"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
    <!-- 复制卡密 -->
    <el-dialog
      :visible.sync="cardOpenVisible"
      @confirm="cardOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="card-title"
      >{{cardInfo.productName}}</p>
      <div
        style="width: 90% !important;margin-left: 5%;"
        class="card-tip"
      >
        <p>卡号：</p>
        <p @click="cardNoCopy">{{ cardInfo.cardNo }}</p>
        <i
          style="margin-left:10px;"
          :data-clipboard-text="cardInfo.cardNo"
          @click="cardNoCopy"
          class="cardNo-copy el-icon-document-copy"
        ></i>
      </div>
      <div
        style="width: 90% !important;margin-left: 5%;"
        class="card-tip"
      >
        <p>卡密：</p>
        <p @click="cardNoCopy">{{ cardInfo.cardPwd }}</p>
        <i
          style="margin-left:10px;"
          :data-clipboard-text="cardInfo.cardPwd"
          @click="cardPwdCopy"
          class="cardPwd-copy el-icon-document-copy"
        ></i>
      </div>

    </el-dialog>
    <!--查看优惠券 -->
    <el-dialog
      :visible.sync="couponOpenVisible"
      @confirm="couponOpenVisible = false"
      class="coupon-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-title"
      >{{couponInfo.couponTitle}}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-use-price"
      >满{{couponInfo.useMinPrice}}减{{couponInfo.couponPrice}}元</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-count"
      >x1</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-tip"
      >已存入账户中</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="coupon-tip"
      >请在“我的”-“优惠券”中查看</p>

    </el-dialog>
  </div>
</template>
<script>
import api from '../api/index-client'
import Cookies from 'js-cookie'
import Clipboard from "clipboard";
import Vue from 'vue'
import { Icon,Menu,Dialog,Radio,RadioGroup,Input,Button,MenuItem,InfiniteScroll } from 'element-ui';
import { List,Popup } from 'vant';
Vue.use(List);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Menu)
Vue.use(Radio)
Vue.use(MenuItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(RadioGroup)
Vue.use(InfiniteScroll)
export default {
  data () {
    return {
      couponOpenVisible: false,
      couponInfo: {
        couponTitle: '优惠券名称优惠券名称优惠券名称优惠券名称优惠券名称',
        useMinPrice: 10,
        couponPrice: 1,
        count: 10,
      },
      cardOpenVisible: false,
      cancerReasonVisible: false,
      cancerReasonText: '',
      cancerReason: '',
      activeIndex: '-7',
      cancerVisible: false,
      confirmVisible: false,
      loading: false,
      finished: false,
      noOrder: false,
      cancerOrderId: null,
      goodsParam: {
        page: 0,
        limit: 12,
      },
      orderList: [],
      cardInfo: {},
      isPicoShowPay: false,
    }
  },
  created () {
    this.isPicoShowPay = Cookies.get('Device') == 'pico'
    // this.getOrderInfo(this.activeIndex== '-7' ? null : this.activeIndex)
  },
  mounted () {
    this.$store.commit('SHOW_APPLOADING')
    this.activeIndex = localStorage.getItem('orderIndex') ? localStorage.getItem('orderIndex') : '-7'
    var tab = ''
    tab = this.activeIndex == '-7' ? null : this.activeIndex
    // this.getOrderInfo(tab)+ this.goodsParam.page + '&limit=' + this.goodsParam.limit + '&type=' + e + '&skuType=1'
  },
  methods: {
    getOrderInfo (e) {
      api
        .post('assets/exchange/orders',{ page: this.goodsParam.page,size: this.goodsParam.limit,status: this.activeIndex == '-7' ? null : this.activeIndex })
        .then(result => {
          if (result.data.success) {
            result.data.data.content.map(i => {
              this.orderList.push(i)
            })
            this.noOrder = this.orderList.length == 0
            if (result.data.data.content.length < this.goodsParam.limit) {
              this.finished = true
            } else {
              this.finished = false
            }
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.loading = false
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    load () {
      this.loading = true
      var tab
      this.goodsParam.page += 1
      tab = this.activeIndex == '-7' ? null : this.activeIndex
      this.getOrderInfo(tab)
    },
    // tab切换
    handleSelect (key,keyPath) {
      localStorage.setItem('orderIndex',key)
      this.goodsParam.page = 1
      this.loading = true
      this.orderList = []
      var tab = ''
      this.activeIndex = key
      tab = key == '-7' ? null : key
      this.getOrderInfo(tab)
    },
    // 查看优惠券详情
    veiwConpon (e) {
      api
        .get('coupons/detail?couponId=' + e.userCouponId)
        .then(result => {
          if (result.data.success) {
            this.couponInfo = result.data.data
            this.couponOpenVisible = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    },
    // 订单详情
    goOrderDetails (e) {
      this.$router.push('/exchangeDetails?orderId=' + e.orderId + '&type=exchange')
    },
    veiwOrder (e) {
      if (e.kdNo) {
        this.$router.push('/logistics?orderId=' + e.orderId + '&type=exchange')
      }
    },
    // 查看卡密
    veiwCard (e) {
      api
        .get('assets/exchange/getCardInfo/' + e.orderId)
        .then(result => {
          if (result.data.success) {
            this.cardInfo = result.data.data
            this.cardOpenVisible = true
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    //确认收货弹框
    orderConfim (e) {
      if (e.kdNo) {
        this.cancerOrderId = e.orderId
        this.confirmVisible = true
      }
    },
    //确认收货事件
    orderConfirm () {
      api
        .post('order/express/confirm/' + this.cancerOrderId)
        .then(result => {
          if (result.data.success) {
            this.$toast.success('成功')
            this.confirmVisible = false
            this.goodsParam.page = 1
            this.orderList = []
            this.activeIndex = '-7'
            localStorage.setItem('orderIndex',-7)
            let e
            e = this.activeIndex == '-7' ? null : this.activeIndex
            this.getOrderInfo(e)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    orderCancer (e) {
      this.cancerOrderId = e.orderId
      this.cancerVisible = true
    },
    // 卡号复制
    cardNoCopy () {
      this.cardOpenVisible = false
      var clipboard = new Clipboard(".cardNo-copy");
      clipboard.on("success",(e) => {
        this.$toast('复制成功');
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast('复制失败');
        // 释放内存
        clipboard.destroy();
      });
    },
    // 卡密复制
    cardPwdCopy () {
      this.cardOpenVisible = false
      var clipboard = new Clipboard(".cardPwd-copy");
      clipboard.on("success",(e) => {
        this.$toast('复制成功');
        //  释放内存
        clipboard.destroy();
      });
      clipboard.on("error",(e) => {
        // 不支持复制
        this.$toast('复制失败');
        // 释放内存
        clipboard.destroy();
      });
    },
    cancerOrderReason () {
      api
        .get('assets/exchange/cancel?orderId=' + this.cancerOrderId)
        .then(result => {
          if (result.data.success) {
            this.$toast.success('成功')
            this.cancerVisible = false
            this.goodsParam.page = 1
            this.orderList = []
            this.activeIndex = '-7'
            localStorage.setItem('orderIndex',-7)
            let e
            e = this.activeIndex == '-7' ? null : this.activeIndex
            this.getOrderInfo(e)
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })


    }
  },
}
</script>
<style lang="less" scoped>
.order-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .order-img-name-img {
    display: flex;
    justify-content: center;
    align-items: center;
    &-inline {
      display: block;
      text-align: center;
      height: 100%;
      width: 100%;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: 0;
        right: 0;
        transform: translate(-50%, -50%);
      }
    }
  }

  .cancer-order-title {
    font-size: 20px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
    margin-top: 1.75rem;
  }
  .cancer-submit {
    width: 90%;
    height: 2.875rem;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .card-title {
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 600;
    color: rgb(204, 204, 204);
    line-height: 1.5625rem;
    margin-bottom: 20px;
  }
  .card-tip {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-family: lantingheiweight;
    font-weight: 500;
    color: rgb(214, 214, 214);
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
  /deep/.el-radio > .el-radio__input > .el-radio__inner {
    border-radius: 100%;
    width: 1.125rem;
    height: 1.125rem;
    background: #545454 !important;
    border: none;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner::after {
    width: 8px;
    height: 8px;
    background: #f3cdb6;
  }

  /deep/.el-radio__label {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #d8d8d8;
    line-height: 20px;
  }

  /deep/.el-textarea__inner {
    margin-top: 16px;
    width: 90%;
    height: 96px;
    background: #4a4a4a;
    border-radius: 4px;
    border: none;
    outline: none;
  }
  .go-disable-pay {
    width: 5rem;
    height: 1.875rem;
    border-radius: 0.9375rem;
    background-image: url('../assets/icon/sold-out.png');
    background-attachment: scroll;
    background-position: 40% 0%;
    background-size: 200% auto;
    background-color: transparent;
    margin-right: 0.75rem;
    font-size: 14px;
    font-family: lantingheiweight;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
  /deep/.v-modal {
    z-index: 2002 !important;
  }
}
.coupon-title {
  font-size: 18px;
  width: 100%;
  text-align: center;
  font-family: lantingheiweight;
  font-weight: 800;
  color: rgb(204, 204, 204);
  line-height: 1.5625rem;
  margin-bottom: 20px;
}
.coupon-use-price {
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-family: lantingheiweight;
  font-weight: 500;
  color: rgb(204, 204, 204);
  line-height: 1.5625rem;
}
.coupon-count {
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 300;
  color: rgb(204, 204, 204);
  line-height: 1.5625rem;
  margin-bottom: 20px;
}
.coupon-tip {
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: rgb(204, 204, 204);
  line-height: 1.5625rem;
}
.coupon-dialog {
  height: auto !important;
  margin-top: 20vh !important;
  /deep/.el-dialog {
    height: 15rem !important;
  }
}
</style>